.scar-link {
  &--container {
    padding: 5px;
  }
}

.MuiAccordionSummary-content.Mui-expanded {
  .morevert-icon-container {
    top: 12px;
  }
}

.accordion-item-with-dropdown-menu {
  .MuiIconButton-edgeEnd {
    margin-right: 35px;
  }
}

.is-benchmark-checkBox {
  padding: 0px 0px 0px 9px !important;
}

.morevert-icon-container {
  top: 0px;
  display: flex;
  align-items: center;
}

.titles-columns-container,
.total-columns-container {
  padding-top: 12px;
}

.titles-columns-container {
  padding-left: 12px;
}

#conclusion-table,
#weighted-share-value {
  padding-left: 12px;
}

.grid-bottom-margin {
  margin-bottom: 5em!important;
}

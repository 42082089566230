$selected-border-color: #2185d0;

.data-grid td {
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $gray300;
  color: $primary700;

  font-size: 14px;
  cursor: cell;
  height: 31px;
  max-width: $maxCellWidth;
  padding: 0 4px; // this is the little padding that keeps from having text sticked to the border
  position: relative;
  text-align: right;
  text-overflow: ellipsis !important;
  transition: background-color 500ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap !important;
  width: $defaultCellWidth;
  box-sizing: content-box;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  &.parent-with-divider:not(.hide),
  &.child-with-divider {
    border-right: 4px solid $primary500 !important;

    &.selected {
      border-right-width: 4px !important;
    }
  }

  &.parent-with-divider {
    border-left: 2px solid $gray400;
  }

  &[data-child-index='1'],
  &[data-parent-expanded='true'] {
    border-left: 4px solid #8a9097 !important;

    &.selected {
      border-left-width: 4px !important;
    }
  }

  &[data-is-parent='true'] {
    border-left: 2px solid #8a9097;

    &.selected {
      border-left: 2px solid $selected-border-color;
    }
  }

  &.row-label-title {
    color: $primary800 !important;
    font-weight: bold !important;
  }

  &.row-with-primary-bg {
    background-color: $primary800 !important;
    font-weight: bold !important;
    color: $white !important;
  }

  &.full-width-label .text-cell {
    min-width: 100%;
  }

  &.delete-row {
    border: none;
    width: 50px;

    & svg {
      fill: $gray500;
      height: 1em;
      width: 1em;
    }
  }

  &.double-height {
    height: 56px;

    .text-cell {
      min-width: auto;
      white-space: pre-line;
    }
  }

  &.formatter-cell {
    max-height: 96px;
    height: 74px !important;

    // Fix for the row legend
    &.legend.row-legend {
      height: auto !important;
    }
  }

  // align to the right cells that span across multiple columns
  &.large-cell-value span.text-cell {
    max-width: 100% !important;
    width: 100% !important;
  }

  .tooltip-indicator {
    height: 16px;
    position: absolute;
    right: -1px;
    top: -1px;
    border-left: 8px solid transparent;
    border-top: 8px solid;
    border-top-color: $blue600;
  }

  &.updated {
    background-color: rgba(0, 145, 253, 0.16);
    transition: background-color 0ms ease;
  }

  &.titles-column-selector {
    .select-value-viewer {
      .text-cell {
        text-align: left !important;
        min-width: 87%;
      }
    }
  }

  &.grid-column-selector {
    .select-value-viewer {
      width: calc(100% - 4px);

      .text-cell {
        text-align: left !important;
        min-width: 87%;
      }
    }
  }

  &.selected {
    border: 1px double $selected-border-color;
    box-shadow: inset 0 -100px 0 rgba(25, 194, 231, 0.1);
    transition: none;

    .tooltip-indicator {
      border-top-color: $selected-border-color;
    }
  }

  &.read-only {
    background-color: $readOnly;
    color: $textColor;

    .tooltip-indicator {
      border-top-color: $gray300;
    }

    &.read-only--white {
      background-color: $white;
      color: $blue600;

      .MuiSvgIcon-root {
        fill: $blue600;
      }

      .MuiInputBase-input {
        color: $blue600;
      }

      &.total-cell {
        background-color: $readOnly;
        color: $textColor;
      }
    }

    .MuiInputBase-input {
      color: $textColor;
    }

    .MuiSvgIcon-root {
      fill: $textColor;
    }

    .link__icon {
      fill: $cellIconColor;
    }

    a {
      color: $textColor;
    }
  }

  &.warning {
    background-color: $warning100;
    border-color: $warning;
    border-style: solid;
    color: $warning;

    .tooltip-indicator {
      border-top-color: $warning;
    }
  }

  &.error {
    background-color: lighten($error, 30%) !important;
    border-color: $error !important;
    border-style: double !important;
    color: $error !important;

    .tooltip-indicator {
      border-top-color: $error;
    }
  }

  & > .text {
    padding: 2px 5px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & > input {
    background: none;
    border: 2px solid $selected-border-color;
    display: block;
    height: 11px;
    text-align: right;
    outline: none !important;
    width: calc(100% - 6px);
  }

  &.editing {
    input {
      border: none;
      color: $primary700;
      font-family: Montserrat, sans-serif;
      font-size: $cellFontSize;
      height: 100%;
    }
  }

  &.date-editor-white.editing {
    input {
      color: $white;
    }

    .error-alert > input {
      color: red;
    }
  }

  &.table-subheader {
    align-items: center;
    background-color: $blue600 !important;
    color: $white;
    font-family: $secondaryFont;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: bold;
    line-height: 2.0625rem;

    .link .link__icon {
      fill: $white;
      background: transparent;
    }

    a {
      color: $white;
    }
  }

  &.table-header {
    border-color: $gray300;
    color: $primary700;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;

    &.smaller-font .text-cell { 
      font-size: 0.8rem;
    }

    &.padded > div > span#row-title {
      padding-left: 0;
    }

    &.align-right > div > span#row-title {
      text-align: right;
      margin-right: 20px;
    }

    .MuiInputBase-input {
      color: $blue600;
    }
    &[data-is-parent='true'] {
      border-left: 2px solid #8a9097;
    }
  }

  &.header-no-border-right {
    border-right: none;
  }

  &.header-no-border-left {
    border-left: none;
  }

  &.header-no-border {
    color: $primary700;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;

    &.no-uppercase {
      text-transform: none;
    }

    &.text-right {
      text-align: right !important;
    }
  }

  &.header-no-border-black {
    color: $textColor;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;

    &.no-uppercase {
      text-transform: none;
    }
  }

  &.subtitle {
    border-bottom: 2px solid black;
    border-top: 2px solid black;
    color: black;
    font-family: $secondaryFont;
    font-weight: 600;

    &-bold {
      font-weight: 600;

      .select-value-viewer {
        .text-cell {
          font-weight: 600;
        }
      }
    }

    &-indented {
      padding-left: 10px;
    }
  }

  &.bold-row {
    font-weight: 600;
  }

  &.black-text {
    color: black !important;
  }

  &.blue-text {
    color: #0c71a6 !important;
  }

  &.backsolve-subtitle {
    &.read-only {
      color: black !important;
    }
    font-family: $secondaryFont;
  }

  &.border-bottom {
    border-bottom: 2px solid $blue700;
  }

  &.border-top {
    border-top: 2px solid $blue700;
  }

  // Bottom border between the fund and firm total rows
  &.subtitle-border-bottom {
    border-bottom: 2px solid $blue700;
    font-family: $secondaryFont;
    color: black;
    font-weight: 700;

    &.blue {
      color: $blue800;
    }
  }

  &.division {
    font-family: $secondaryFont;
    font-weight: 600;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
  }

  &.division-top-only {
    border-top: 2px solid black;
  }

  &.division-bottom-only {
    border-bottom: 2px solid black;
  }

  &.subheader-division {
    &,
    &.selected {
      background-color: $blue600 !important;
      border: 1px solid $blue600;
      box-shadow: none;
    }
  }

  &.subtotal-division {
    font-weight: 600;
  }

  .center {
    text-align: center;
  }

  &.legend {
    font-size: 12px;
    color: gray;
    text-align: center !important;
  }

  &.row-title {
    color: black;
    font-size: smaller;
    font-weight: 500;
    text-transform: uppercase;
  }

  svg {
    fill: $blue600;
    height: 24px;
    width: 16px;
  }

  //this is for the keyboard date picker in captable
  .MuiInputAdornment-positionEnd {
    margin-left: 5px;

    button {
      padding: 0;
    }
  }

  // cell with dialog
  //This is the one with the ledger square
  .dialog {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .dialog__value {
      flex-grow: 0.1;

      & > span {
        justify-content: flex-end;
      }

      & + div > button.MuiButtonBase-root.MuiIconButton-root {
        padding: 1px 1px 1px 5px;
        margin-right: -2px;
      }

      .text-cell {
        width: calc(100% - 4.3em);
        right: 1.8em;
        min-width: 100px;
      }

      .dropdown-icon {
        position: absolute;
        right: 2.2em;
      }
    }
  }

  // cell with link
  .link {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    .link__value {
      width: 2rem;
      flex-grow: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-right: 14px;
    }

    .link__icon {
      opacity: 0;
      position: absolute;
      top: -5px;
      right: -100%;
      padding-left: 1.5rem;
      background: $white;
      background: linear-gradient(90deg, rgba($white, 0) 0%, rgba($white, 1) 50%);
    }

    &:hover .link__icon {
      right: 0;
      opacity: 1;
      transition: ease all 0.25s;
    }
  }

  .MuiInputBase-root {
    color: rgba(0, 0, 0, 0.5) !important;
  }

  .MuiInputBase-input {
    color: $blue600;
    font-size: $cellFontSize;
    text-align: end;
  }

  .error-alert .MuiInputBase-input {
    color: red;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid $gray200 !important;
  }

  .data-grid-container .data-grid {
    background-color: $white;
  }

  // STYLES FOR FIXED TABLE CELL WIDTH
  // default input
  .MuiInput-root {
    max-width: $maxCellWidth;
  }

  .select-value-viewer {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;

    .text-cell {
      font-weight: 400;
      left: 10px;
      max-width: 100%;
      padding: 0;
      position: absolute;
      right: 20px;
      text-align: right;
      top: 5px;
      width: auto;

      > span:first-child {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .dropdown-icon {
      position: absolute;
      right: 2px;
      top: 4px;
    }
  }

  &.grid-select-left {
    color: $textColor;
    .select-value-viewer {
      .text-cell {
        text-align: left;
        width: calc(100% - 2em);
      }
    }
  }

  .data-editor,
  .value-viewer {
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    display: flex;
    height: 100%;
    width: 100%;
  }

  .cell,
  .text-cell {
    font-size: $cellFontSize;
    max-width: $maxCellWidth;
    min-width: 80%;

    &.align-right {
      text-align: right;
    }

    width: $defaultCellWidth;
    padding-right: 20px;
    overflow: hidden;
    white-space: nowrap;
  }

  &.table-header,
  &.header-no-border,
  &.table-header-comp-groups-resize-column {
    .cell,
    .text-cell {
      white-space: normal;
      box-sizing: border-box;
      text-overflow: unset; /* Disable ellipsis */
      display: -webkit-box;
      -webkit-line-clamp: 3; /* Show maximum of 3 lines */
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }
  }

  &.firefox-height {
    height: -moz-max-content;
  }

  &.sm-cell,
  &.sm-cell .text-cell {
    max-width: 150px;
    width: 150px;
  }

  // STYLES FOR THE ROW LEGEND
  // to hide cells when displaying the toolbar in captable
  &.legend.hidden-legend {
    display: none;
  }

  &.legend.row-legend {
    max-width: 25px;
    padding: 0;
    width: 25px;

    .text-cell,
    .value-viewer {
      font-size: 12px;
      max-width: 25px;
      min-width: 100%;
      width: 25px;
      color: gray;
      justify-content: normal;
      text-align: center;
    }
  }

  // STYLES FOR COL LEGEND LETTER
  &.legend.col-legend,
  &.col-legend {
    font-size: 12px !important;
    height: 25px;
    text-align: center !important;

    .value-viewer,
    .text-cell {
      font-size: 12px !important;
      color: gray;
      text-align: center;
    }
  }

  &.row-label {
    color: black;

    &-bold small {
      font-weight: 700;
    }

    &-indented small {
      padding-left: 10px;
    }

    background-color: $white;
    max-width: $defaultLabelCellWidth;
    min-width: $defaultLabelCellWidth;
    width: $defaultLabelCellWidth;

    &.spreadsheet-table-title > div {
      font-weight: 700;
    }

    &.spreadsheet-table-title-financials > div {
      font-weight: 700;
      height: 95px !important;
    }

    &.spreadsheet-table-title-summary > div {
      height: 30.5px !important;
    }

    small {
      font-size: $cellFontSize;
    }

    &.editable .data-editor {
      text-align: left;
    }

    &.gray-title-cell {
      background-color: $gray100;

      &.blue-text {
        color: $blue800;
        font-weight: 600 !important;
        padding-left: 1.5rem;
      }
    }
  }

  &.col-lg {
    min-width: 293px;
  }

  &.gray-cell {
    background-color: $gray100;
  }

  // not all cells with white background must have the size of a row label
  &.title-col {
    background-color: white !important;
  }

  &.onlyTopBorder {
    background-color: white !important;
    border-bottom: none !important;
    height: 31.5px !important;
  }

  &.onlyBottomBorder {
    background-color: white !important;
    border-top: none !important;
    height: 31.5px !important;
  }

  &.align-center {
    .value-viewer {
      #row-title {
        text-align: center;
      }
    }
  }
  &.align-right {
    .cell,
    .text-cell {
      text-align: right;
      margin-right: 20px;
    }
  }
}

// specific styles to Fund Ownership
#fund-ownership-spreadsheet-table .data-grid td {
  .MuiFormControl-root {
    padding: 0;
  }

  .MuiIconButton-sizeSmall {
    padding: 3px 2px 3px 6px;
  }

  .dialog__value > span {
    justify-content: flex-end;
  }

  &.row-label {
    .MuiIconButton-sizeSmall {
      padding: 3px 2px 3px 4px;
    }

    .dialog__value > span {
      justify-content: flex-end;
    }
  }
}

#cash-distribution-ledger,
#shares-ledger {
  .data-grid td:first-child span.value-viewer span.text-cell {
    padding-right: 20px;
  }
  .data-grid td:first-child span.value-viewer {
    text-align: left;
  }
}

#approach-opm-input-table {
  tr td {
    &:first-child {
      .value-viewer,
      .text-cell {
        text-align: left;
        padding-right: 0px;
        margin-left: 0px;
      }
    }
  }
}
// specific styles to Financials

#balance-sheet-spreadsheet-table .data-grid td.row-label {
  // this could still be refactor to extract &.row-label.
  &[data-parent='total_cash_equivalents'] small,
  &[data-parent='total_debt'] small {
    padding-left: 10px;
  }

  &[data-parent=''] small {
    font-weight: 700;
  }
}

#scrollbar-income-statement,
#scrollbar-balance-sheet,
#scrollbar-valuation-table-dcf,
#scrollbar-financials-performance-metrics {
  .data-grid td {
    .value-viewer {
      text-align: right;
      width: 88%;
      .col-legend {
        text-align: center !important;
        width: 100%;
        &.ltm-or-ntm-legend {
          color: $white;
          border-bottom: none;
        }
      }
    }

    .text-cell {
      padding-right: 0;
    }
  }
}

#scrollbar-valuation-table-dcf .data-grid td.row-label .value-viewer {
  text-align: center;
  width: 100%;
}

// specific styles to Comp Groups
.name-table div {
  margin-left: 0.5rem;
  text-align: left;
  font-weight: bold !important;
}

.name-table-header {
  td.table-header {
    background-color: $white !important;
    min-width: 17.9rem;
    color: #075185;
  }
  td.comp-group-name-cell div div {
    padding-right: 20px;
    span {
      .text-cell {
        padding-right: 0px;
        text-overflow: ellipsis;
      }
    }
  }
}

.data-grid.comp-datasheet td.read-only {
  &.table-header-comp-groups {
    background-color: $white;
    height: 53px;
    white-space: normal !important;
    min-width: 17.9rem;
    border-color: $gray300;
    border-bottom: #0c71a6 solid 3px;
    color: #075185;
    font-weight: 700;
  }

  &.table-header-comp-groups-resize-column {
    background-color: $white;
    height: 53px;
    white-space: normal !important;
    border-color: $gray300;
    border-bottom: #0c71a6 solid 3px;
    color: #075185;
    font-weight: 700;
    min-width: 10rem;
  }
}

.data-grid.comp-datasheet {
  td.first-row {
    .text-cell {
      font-size: 16px;
    }
  }
  .transaction-comp-group-name {
    margin-right: 20px;
  }
  .align-left {
    text-align: left;
  }
}

.deleteable-row-comp-name {
  width: 18.462rem !important;
  max-width: 18.75rem !important;
  text-align: start;
}

// END styles to Comp Groups

// specific styles to Valuations

.weighted-shares-table-title > div > small {
  font-size: 14px;
  font-weight: 700;
}

#valuation-table-container {
  width: 100%;
}

.unselected-checkbox-cell {
  margin-right: 20px;
}

#valuation-gpc-spreadsheet-table .data-grid td {
  // give space for reference companies revuene and EBITDA to the right

  // row labels
  &.row-label.weighted-ev-label small {
    font-weight: 700;
  }

  // dropdown
  div.select-value-viewer > span {
    margin-right: 0;
  }

  &.table-header {
    height: 1.9rem;
  }
}

#spreadsheet-table__valuation-gpc {
  .titles-columns-container {
    tr {
      td.company-title {
        .text-cell {
          width: 100%;
          min-width: 100%;
          max-width: none;
          padding-right: 0px;
        }
      }
    }
  }
}

// specific styles for Allocation
#allocation-spreadsheet-table .data-grid td {
  &.row-label {
    &[data-parent='aggregate_values'],
    &[data-parent='future_values'],
    &[data-parent='present_values'] {
      small {
        padding-left: 10px;
      }
    }
  }

  & .MuiSelect-select.MuiSelect-selectMenu {
    padding-right: 20px;
  }

  & svg.MuiSvgIcon-root.MuiSelect-icon {
    margin-right: -2px;
  }
}

#smaller-target-value-table {
  width: 160px;
  margin-left: 24px;

  .data-grid td {
    .text-cell {
      max-width: 125px;
      min-width: 88%;
      text-align: right;
    }

    #row-title {
      width: 130px;
    }
  }
}

.data-grid td.large-header {
  height: 55px;
}

.data-grid {
  tr {
    td.no-side-border:first-child {
      border-right: none;
      border-left: 1px solid $gray300;
    }

    td.no-side-border:last-child {
      border-left: none;
      border-right: 1px solid $gray300;
    }
  }

  td.no-side-border {
    border-right: none;
    border-left: none;
  }
}

// specific styles for Breakpoint Analysis
#waterfall-breakpoints,
#custom-breakpoints {
  .data-grid {
    td.subtitle {
      & > div > small {
        font-weight: 700;
        font-size: 14px;
      }
    }

    &.waterfall-custom-width-spreadsheet td {
      max-width: 160px;
    }
  }
}

// Styles for the waterfall-inputs and the distribution tables in the waterfall page
#distribution-table,
#waterfall-inputs {
  .data-grid td {
    text-align: left;
    .value-viewer {
      text-align: left;
      justify-content: flex-start;
    }
    &:first-child(:not(.align-right, .align-left)) {
      .cell,
      .text-cell {
        text-align: left;
      }
      &.table-header .value-viewer {
        text-align: left;
      }
    }
    &.align-left {
      .value-viewer {
        text-align: left;
        justify-content: flex-start;
      }
    }
    &.align-right {
      .value-viewer {
        text-align: right;
        justify-content: flex-end;
        .text-cell {
          margin-right: 0px;
        }
      }
      .cell,
      .text-cell {
        text-align: right;
        margin-right: 0px;
      }
    }
    &#CELL-ev {
      .value-viewer {
        text-align: right;
      }
    }
  }
}

#distribution-table {
  .data-grid td,
  tr {
    height: 2em;
  }
}

.valuation-summary-sc-spreadsheet td.formatter-cell {
  height: 31.5px !important;

  &.summary-table-header-valuations {
    height: 95px !important;
  }
}

.valuation-trends-sc-spreadsheet td,
.valuation-methods-detail-sc-spreadsheet td,
.comparison-companies-sc-spreadsheet td,
.valuation-gpc-sc-spreadsheet td,
.valuation-gpt-sc-spreadsheet td,
.income-statement-sc-spreadsheet td,
.balance-sheet-sc-spreadsheet td {
  &.formatter-cell {
    height: 31px !important;

    &.gpc-table-header {
      height: 62px !important;
    }

    &.trends-table-header {
      height: 63px !important;
    }

    &.comparison-companies-table-header,
    &.val-methods-table-header {
      height: 63px !important;

      & .value-viewer > .text-cell {
        padding-right: 0 !important;
        text-overflow: initial !important;
        white-space: break-spaces !important;
      }
    }

    &.summary-table-header-valuations {
      height: 95px !important;
    }

    &.row-span-2 {
      height: 63px !important;
    }

    &.row-span-3 {
      height: 95px !important;
    }
  }

  &.row-span-2 {
    height: 63px !important;
  }

  &.row-span-3 {
    height: 95px !important;
  }
}

.kpi-sc-spreadsheet {
  & div#spreadsheet-table__title-columns {
    .row-label {
      span.value-viewer {
        justify-content: flex-start;
        text-align: left;
      }
    }
  }

  td {
    &.row-span-3 {
      height: 95px !important;
    }

    &.formatter-cell[data-row-span='1'] {
      height: 31px !important;
    }
    &.formatter-cell[data-row-span='2'] {
      height: 63px !important;
    }
    &.formatter-cell[data-row-span='3'] {
      height: 95px !important;
    }
  }
}

// Align GPC/GPT Header Titles
.valuation-gpc-sc-spreadsheet td,
.valuation-gpt-sc-spreadsheet td {
  &.table-header {
    .value-viewer {
      justify-content: flex-end;
    }
  }

  &.table-header.full-width-label {
    &.table-header {
      .value-viewer {
        text-align: center;
        margin-right: 0;
      }
    }
  }
}

.valuation-gpt-sc-spreadsheet td {
  &.table-header {
    .text-cell {
      display: inline;
    }
  }
}

// Financials Perfomance Metrics
.financials-performance-metrics-sc-spreadsheet td.row-span-3 {
  height: 95px !important;
}

.valuation-methods-detail-sc-spreadsheet .data-grid td.row-label,
.valuation-trends-sc-spreadsheet .data-grid td.row-label {
  max-width: 400px !important;
  width: 400px !important;
}

.waterfall-calculator-sc-spreadsheet #spreadsheet-table__content-columns {
  width: auto !important;
}

.inline-tables {
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 1em;

  .data-table {
    display: inline-block;
    margin-right: 1em;
  }
}

// END Styles for the distribution tables in the waterfall page

.data-grid-container .data-grid,
.data-grid-container .data-grid.data-sheet td.read-only {
  background-color: $readOnly;
}

.data-grid.data-sheet td.read-only {
  &.table-header {
    background-color: $white;
    height: 53px;
    white-space: normal !important;
    min-width: 130px;
  }

  span.text-cell {
    width: 123px;
  }

  &:first-child span.value-viewer {
    a,
    span {
      width: 175px;
      text-align: left;
      padding-left: 10px;
    }
  }

  &.wide-cell-y {
    height: 36px;
  }
}

// Drag and drop columns
.cell {
  &.ltr-left,
  &.rtl-left {
    box-shadow: inset 3px 0px 0px $primaryColor;
  }

  &.ltr-right,
  &.rtl-right {
    box-shadow: inset -3px 0px 0px $primaryColor;
  }
}

.dragging-column-container {
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.dragging-column {
  width: $defaultCellWidth;
  border: 2px solid $blue600;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1000;
  background-color: $white;
}

.custom-width {
  max-width: inherit !important;
}

.title-first div,
.title-first-alternative div {
  text-align: left;
  font-weight: bold !important;
}

.title-second div {
  text-align: left !important;
  margin-left: 2em;
  .effective-tax-rate-chip {
    margin-right: 2em;
  }
}

.title-third div {
  text-align: left;
  margin-left: 3em;
  text-transform: capitalize;
}

.title-first-alternative b,
.title-second b,
.title-third b {
  font-weight: normal !important;
}

.bigger-row-label {
  max-width: 410px !important;
  min-width: 410px !important;
  width: 410px !important;
}

.bigger-row-label-wacc-ledger {
  max-width: 470px !important;
  min-width: 470px !important;
  width: 470px !important;
  background-color: white !important;
}

.title-center div {
  text-align: center !important;
}

.spreadsheet-table-allocation-title-summary-valuation .text-cell {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
}

.ledger-dialog {
  .data-grid {
    &.white-table-header {
      background-color: white;

      td.table-header {
        background-color: white !important;
      }
    }
  }
}

//Breakeven range cell
.breakeven-cell {
  max-width: none !important;
  width: auto !important;
}

// Styles for disabled cells in Fund and Company Summary Table
.data-grid-container {
  .data-grid {
    &.data-sheet {
      td {
        &.cell {
          &,
          &.read-only {
            &[data-disabled='true'] {
              background-color: $disabled;
              border-color: $disabled;
              box-shadow: none;
              color: $gray400;
              cursor: auto;
            }
          }
        }
      }
    }
  }
}

// Summary tables header styles
.summary-content {
  .data-grid {
    &.data-sheet {
      td {
        // Table header
        &.table-header {
          &.read-only {
            .cell,
            .text-cell {
              padding: 5px 0;
              padding-right: 20px;
              text-align: right;

              // Reset Text Overflow Ellipsis
              text-overflow: clip;
              overflow: visible;
              white-space: normal;

              min-width: 0;
              max-width: none;
              width: 100%;
            }

            &:first-child {
              .cell,
              .text-cell {
                text-align: left;
                padding-left: 10px;
              }
            }
          }
        }

        &:not(.table-header) {
          &.read-only {
            span {
              &.text-cell {
                margin: 0 auto;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  // Valuation Summary Table - Fund Summary Page
  &.valuations-summary {
    .data-grid {
      &.data-sheet {
        td {
          // Table header
          &.table-header {
            &.read-only {
              // Valuation Status
              // Enterprise Valuation Method
              &:nth-child(3),
              &:nth-child(4) {
                .cell,
                .text-cell {
                  text-align: left;
                  padding-left: 10px;
                  padding-right: 0;
                }
              }
            }
          }

          &:not(.table-header) {
            &.read-only {
              // Enterprise Valuation Method
              &:nth-child(4) {
                .cell,
                .text-cell {
                  text-align: left;
                  padding-left: 10px;
                }

                span {
                  &.text-cell {
                    margin-right: auto;
                    margin-left: 0;
                  }
                }
              }

              // Valuation Status
              .MuiInputBase-input {
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}

// End Styles for disabled cells in Fund Summary Table

// Styles for the Specified Share Values table in Valuation Page
#security-shares-table {
  .data-grid {
    td {
      &:first-child {
        .cell,
        .text-cell {
          text-align: left;
        }
      }
      &.align-right {
        .cell,
        .text-cell {
          text-align: right;
        }
      }
    }
  }
}

#spreadsheet-table {
  &__opm {
    .data-grid td {
      &.table-header {
        #row-title {
          text-align: right;
          margin-right: 20px;
        }
      }
    }
  }
  &__backsolve-summary-table,
  &__market-adjustment-table {
    .name-table div {
      margin-left: 0;
    }
    .data-grid td {
      &:first-child {
        .value-viewer {
          text-align: left;
        }
      }
    }
  }
  &__market-adjustment-table {
    .data-grid td {
      .text-cell {
        text-overflow: ellipsis;
      }
    }
  }
  &__market-adjustment-ledger {
    .data-grid td {
      .value-viewer {
        .title-align-left {
          text-align: left;
        }
      }
    }
  }
  &__allocation-scenarios {
    .data-grid td {
      .text-cell {
        display: inline;
        box-sizing: content-box;
      }
    }
  }
}

.transactions-table-comps,
.gpc-table-comps {
  &.data-grid td {
    &.table-header-comp-groups.first-row {
      #row-title {
        text-align: left;
      }
    }
  }
}

.data-grid.name-table-header {
  td.table-header {
    .value-viewer {
      text-align: left;
    }
  }
}

#cap-table-selection {
  .data-grid {
    tbody {
      td {
        &:first-child {
          &.table-header .value-viewer {
            text-align: left;
          }
        }
      }
    }
  }
}

// External Valuation
.external-valuation-sc-spreadsheet {
  #spreadsheet-table__content-columns {
    .data-grid tr {
      &:nth-child(2) {
        .text-cell {
          white-space: nowrap !important;
          display: inline !important;
        }
      }
    }
  }
}

.custom-breakpoints-sc-spreadsheet,
.calculated-breakpoints-sc-spreadsheet {
  #spreadsheet-table__content-columns {
    .data-grid tr {
      td.table-header {
        .value-viewer {
          .text-cell {
            display: inline;
            padding-right: 20px;
          }
        }
      }
    }
  }
}

#spreadsheet-table__convertible-note-ledger {
  #spreadsheet-table__content-columns {
    .table-header {
      max-width: 175px;
      .value-viewer {
        .text-cell {
          display: inline;
          white-space: nowrap !important;
          overflow: hidden;
          text-overflow: ellipsis !important;
        }
      }
    }
  }
}

#spreadsheet-table__future-equity-value,
#spreadsheet-table__modified-present-equity-value,
#spreadsheet-table__allocation-method-table,
#spreadsheet-table__future-values-per-share,
#spreadsheet-table__present-values-per-share {
  .data-grid td {
    &.titles-column-selector {
      .select-value-viewer {
        .text-cell {
          text-align: left !important;
          min-width: 90%;
        }
      }
    }
    &.row-label {
      &.indented {
        padding-left: 15px;
      }
      &.read-only {
        background-color: $readOnly;
        color: $textColor;
      }
    }
  }
}

#spreadsheet-table__allocation-method-table {
  .data-grid td {
    &.row-label-title {
      max-width: 285px !important;
      min-width: 285px !important;
      width: 285px !important;
      color: black !important;
      font-weight: normal !important;
    }
  }
  #spreadsheet-table__content-columns {
    .data-grid td {
      &.row-label {
        max-width: 165px !important;
        min-width: 165px !important;
        width: 165px !important;
        color: #0c71a6 !important;
        font-weight: normal !important;
      }
    }
  }
}

#spreadsheet-table__future-values-per-share,
#spreadsheet-table__present-values-per-share {
  #spreadsheet-table__content-columns {
    .data-grid td {
      &.row-label {
        max-width: 285px !important;
        min-width: 285px !important;
        width: 285px !important;
        color: black !important;
        font-weight: normal !important;
      }
    }
  }
}

// Function to calculate the columns width based on the class
@function get-valuations-allocation-columns($class, $should-adjust: false) {
  $valuation-allocation-columns: 210px;

  @if $class == 'valuations-valuation-summary-container' {
    // Create ternary return based on should-adjust
    @if $should-adjust {
      @return $valuation-allocation-columns - 6px;
    } @else {
      @return $valuation-allocation-columns;
    }
  } @else {
    @return $valuation-allocation-columns;
  }
}

// Mixin to apply the columns width
@mixin apply-valuations-allocation-columns($class) {
  .spreadsheet-container {
    .data-grid {
      td {
        // Titles Columns
        &.row-label {
          min-width: get-valuations-allocation-columns($class, true) + 100px;
        }

        // Header Columns
        &.large-header,
        &.subtitle,
        &.table-header {
          min-width: get-valuations-allocation-columns($class);
        }

        // Header Titles
        &.table-header {
          &,
          .text-cell {
            min-width: get-valuations-allocation-columns($class);
          }
        }
      }
    }
  }
}

// Valuations Spreadsheets Container

.valuations-container {
  .spreadsheet-container {
    .data-grid {
      td {
        &.w-full {
          .value-viewer {
            .text-cell {
              max-width: none;
              min-width: auto;
              padding: 0 20px;
              width: 100%;
            }
          }
        }

        &.text-center {
          .value-viewer {
            .text-cell {
              text-align: center;
            }
          }
        }
      }
    }
  }

  // Valuation Summary
  .valuations-valuation-summary-container {
    @include apply-valuations-allocation-columns('valuations-valuation-summary-container');
  }

  // Equity Allocation, Weighted Share Values
  .valuations-equity-allocation-container,
  .valuations-weighted-share-values-container {
    @include apply-valuations-allocation-columns('');
  }
}

:root {
  --blue-300: #0a88d7;
  --blue-500: #037de8;
}

:export {
  primary900: #043a6e;
  primary800: #075185;
  primary700: #0c71a6;
  primary600: #004a8b;
  primary500: #037de8;
  primary400: #0386de;
  primary300: #0a88d7;
  primary200: #76c4f5;
  primary100: #afe0ff;
  primary50: #ddf6fc;
  primaryColor: #037de8;
  blue: #0386de;
  blue100: #afe0ff;
  blue300: #0a88d7;
  gray900: #0e1427;
  gray800: #171e2f;
  gray700: #242c3b;
  gray600: #353c46;
  gray500: #494d52;
  gray400: #8a9097;
  gray300: #bdc4cb;
  gray200: #e3e9ed;
  gray100: #f0f4f6;
  black300: #293033;
  white: #fff;
  secondary900: #135f4b;
  secondary800: #207255;
  secondary700: #328e65;
  secondary600: #49aa75;
  secondary500: #65c687;
  secondary400: #8bdca0;
  secondary300: #a7edb2;
  secondary200: #c8f9cb;
  secondary100: #e4fce3;
  green: #00b200;
  green500: #65c687;
  cellError: #ffe9e9;
  readOnly: #f9fcfc;
  disabled: #f0f4f6;
  textColor: black;
  cellIconColor: #004a8b;
  defaultCellWidth: 175px;
  defaultLabelCellWidth: 275px;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
nav,
section,
menu,
time,
mark,
audio,
video,
canvas {
  border: 0;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans", sans-serif, arial;
}

body {
  background-color: #e3e9ed;
  height: 100%;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: #0a88d7;
}

#root {
  height: 100%;
}

.MuiDrawer-paperAnchorDockedLeft {
  border: none !important;
}

.ps__thumb-x {
  background-color: #79797b;
}

.ps.always-visible .ps__rail-x {
  opacity: 0.6;
}

.ps.always-visible .ps__rail-y {
  opacity: 0.6;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-appearance: menulist-button;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  background-color: #fff !important;
  background-image: none !important;
  color: currentColor !important;
}

.MuiSelect-select:focus {
  background-color: #fff !important;
}

.MuiTypography-h6 {
  font-size: 22px !important;
}

.MuiAccordionSummary-content {
  align-items: center;
}

.underlying-heading {
  color: #293033;
  font-family: "Open Sans", sans-serif, arial;
  font-size: 0.75em;
  font-style: normal;
  font-weight: bold;
  line-height: 1em;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 2.1875em;
  width: 100%;
}
.underlying-heading::after {
  content: "";
  position: absolute;
  width: 30%;
  border: 1px solid #65c687;
  bottom: -10px;
  left: 35%;
}

.date-text,
.amount-text {
  font-family: "Kadwa", serif, times;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125em;
  line-height: 1.0625rem;
}

.scalar-logo {
  height: 1.875rem;
}
.scalar-logo .scalar-logo--text {
  fill: #293033;
}
.scalar-logo .scalar-logo--circle {
  fill: #293033;
}
.scalar-logo .scalar-logo--icon {
  fill: #fff;
}

.scalar-version-icon {
  vertical-align: -2px;
  padding-left: 0.25rem;
}

.data-grid td {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdc4cb;
  color: #0c71a6;
  font-size: 14px;
  cursor: cell;
  height: 31px;
  max-width: 175px;
  padding: 0 4px;
  position: relative;
  text-align: right;
  text-overflow: ellipsis !important;
  transition: background-color 500ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap !important;
  width: 175px;
  box-sizing: content-box;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.data-grid td.parent-with-divider:not(.hide), .data-grid td.child-with-divider {
  border-right: 4px solid #037de8 !important;
}
.data-grid td.parent-with-divider:not(.hide).selected, .data-grid td.child-with-divider.selected {
  border-right-width: 4px !important;
}
.data-grid td.parent-with-divider {
  border-left: 2px solid #8a9097;
}
.data-grid td[data-child-index="1"], .data-grid td[data-parent-expanded=true] {
  border-left: 4px solid #8a9097 !important;
}
.data-grid td[data-child-index="1"].selected, .data-grid td[data-parent-expanded=true].selected {
  border-left-width: 4px !important;
}
.data-grid td[data-is-parent=true] {
  border-left: 2px solid #8a9097;
}
.data-grid td[data-is-parent=true].selected {
  border-left: 2px solid #2185d0;
}
.data-grid td.row-label-title {
  color: #075185 !important;
  font-weight: bold !important;
}
.data-grid td.row-with-primary-bg {
  background-color: #075185 !important;
  font-weight: bold !important;
  color: #fff !important;
}
.data-grid td.full-width-label .text-cell {
  min-width: 100%;
}
.data-grid td.delete-row {
  border: none;
  width: 50px;
}
.data-grid td.delete-row svg {
  fill: #494d52;
  height: 1em;
  width: 1em;
}
.data-grid td.double-height {
  height: 56px;
}
.data-grid td.double-height .text-cell {
  min-width: auto;
  white-space: pre-line;
}
.data-grid td.formatter-cell {
  max-height: 96px;
  height: 74px !important;
}
.data-grid td.formatter-cell.legend.row-legend {
  height: auto !important;
}
.data-grid td.large-cell-value span.text-cell {
  max-width: 100% !important;
  width: 100% !important;
}
.data-grid td .tooltip-indicator {
  height: 16px;
  position: absolute;
  right: -1px;
  top: -1px;
  border-left: 8px solid transparent;
  border-top: 8px solid;
  border-top-color: #004a8b;
}
.data-grid td.updated {
  background-color: rgba(0, 145, 253, 0.16);
  transition: background-color 0ms ease;
}
.data-grid td.titles-column-selector .select-value-viewer .text-cell {
  text-align: left !important;
  min-width: 87%;
}
.data-grid td.grid-column-selector .select-value-viewer {
  width: calc(100% - 4px);
}
.data-grid td.grid-column-selector .select-value-viewer .text-cell {
  text-align: left !important;
  min-width: 87%;
}
.data-grid td.selected {
  border: 1px double #2185d0;
  box-shadow: inset 0 -100px 0 rgba(25, 194, 231, 0.1);
  transition: none;
}
.data-grid td.selected .tooltip-indicator {
  border-top-color: #2185d0;
}
.data-grid td.read-only {
  background-color: #f9fcfc;
  color: black;
}
.data-grid td.read-only .tooltip-indicator {
  border-top-color: #bdc4cb;
}
.data-grid td.read-only.read-only--white {
  background-color: #fff;
  color: #004a8b;
}
.data-grid td.read-only.read-only--white .MuiSvgIcon-root {
  fill: #004a8b;
}
.data-grid td.read-only.read-only--white .MuiInputBase-input {
  color: #004a8b;
}
.data-grid td.read-only.read-only--white.total-cell {
  background-color: #f9fcfc;
  color: black;
}
.data-grid td.read-only .MuiInputBase-input {
  color: black;
}
.data-grid td.read-only .MuiSvgIcon-root {
  fill: black;
}
.data-grid td.read-only .link__icon {
  fill: #004a8b;
}
.data-grid td.read-only a {
  color: black;
}
.data-grid td.warning {
  background-color: #fff4de;
  border-color: #dea638;
  border-style: solid;
  color: #dea638;
}
.data-grid td.warning .tooltip-indicator {
  border-top-color: #dea638;
}
.data-grid td.error {
  background-color: #ffd9e3 !important;
  border-color: #ff4070 !important;
  border-style: double !important;
  color: #ff4070 !important;
}
.data-grid td.error .tooltip-indicator {
  border-top-color: #ff4070;
}
.data-grid td > .text {
  padding: 2px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.data-grid td > input {
  background: none;
  border: 2px solid #2185d0;
  display: block;
  height: 11px;
  text-align: right;
  outline: none !important;
  width: calc(100% - 6px);
}
.data-grid td.editing input {
  border: none;
  color: #0c71a6;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  height: 100%;
}
.data-grid td.date-editor-white.editing input {
  color: #fff;
}
.data-grid td.date-editor-white.editing .error-alert > input {
  color: red;
}
.data-grid td.table-subheader {
  align-items: center;
  background-color: #004a8b !important;
  color: #fff;
  font-family: "Open Sans", sans-serif, arial;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: bold;
  line-height: 2.0625rem;
}
.data-grid td.table-subheader .link .link__icon {
  fill: #fff;
  background: transparent;
}
.data-grid td.table-subheader a {
  color: #fff;
}
.data-grid td.table-header {
  border-color: #bdc4cb;
  color: #0c71a6;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}
.data-grid td.table-header.smaller-font .text-cell {
  font-size: 0.8rem;
}
.data-grid td.table-header.padded > div > span#row-title {
  padding-left: 0;
}
.data-grid td.table-header.align-right > div > span#row-title {
  text-align: right;
  margin-right: 20px;
}
.data-grid td.table-header .MuiInputBase-input {
  color: #004a8b;
}
.data-grid td.table-header[data-is-parent=true] {
  border-left: 2px solid #8a9097;
}
.data-grid td.header-no-border-right {
  border-right: none;
}
.data-grid td.header-no-border-left {
  border-left: none;
}
.data-grid td.header-no-border {
  color: #0c71a6;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.data-grid td.header-no-border.no-uppercase {
  text-transform: none;
}
.data-grid td.header-no-border.text-right {
  text-align: right !important;
}
.data-grid td.header-no-border-black {
  color: black;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}
.data-grid td.header-no-border-black.no-uppercase {
  text-transform: none;
}
.data-grid td.subtitle {
  border-bottom: 2px solid black;
  border-top: 2px solid black;
  color: black;
  font-family: "Open Sans", sans-serif, arial;
  font-weight: 600;
}
.data-grid td.subtitle-bold {
  font-weight: 600;
}
.data-grid td.subtitle-bold .select-value-viewer .text-cell {
  font-weight: 600;
}
.data-grid td.subtitle-indented {
  padding-left: 10px;
}
.data-grid td.bold-row {
  font-weight: 600;
}
.data-grid td.black-text {
  color: black !important;
}
.data-grid td.blue-text {
  color: #0c71a6 !important;
}
.data-grid td.backsolve-subtitle {
  font-family: "Open Sans", sans-serif, arial;
}
.data-grid td.backsolve-subtitle.read-only {
  color: black !important;
}
.data-grid td.border-bottom {
  border-bottom: 2px solid #0c71a6;
}
.data-grid td.border-top {
  border-top: 2px solid #0c71a6;
}
.data-grid td.subtitle-border-bottom {
  border-bottom: 2px solid #0c71a6;
  font-family: "Open Sans", sans-serif, arial;
  color: black;
  font-weight: 700;
}
.data-grid td.subtitle-border-bottom.blue {
  color: #075185;
}
.data-grid td.division {
  font-family: "Open Sans", sans-serif, arial;
  font-weight: 600;
  border-bottom: 2px solid black;
  border-top: 2px solid black;
}
.data-grid td.division-top-only {
  border-top: 2px solid black;
}
.data-grid td.division-bottom-only {
  border-bottom: 2px solid black;
}
.data-grid td.subheader-division, .data-grid td.subheader-division.selected {
  background-color: #004a8b !important;
  border: 1px solid #004a8b;
  box-shadow: none;
}
.data-grid td.subtotal-division {
  font-weight: 600;
}
.data-grid td .center {
  text-align: center;
}
.data-grid td.legend {
  font-size: 12px;
  color: gray;
  text-align: center !important;
}
.data-grid td.row-title {
  color: black;
  font-size: smaller;
  font-weight: 500;
  text-transform: uppercase;
}
.data-grid td svg {
  fill: #004a8b;
  height: 24px;
  width: 16px;
}
.data-grid td .MuiInputAdornment-positionEnd {
  margin-left: 5px;
}
.data-grid td .MuiInputAdornment-positionEnd button {
  padding: 0;
}
.data-grid td .dialog {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.data-grid td .dialog .dialog__value {
  flex-grow: 0.1;
}
.data-grid td .dialog .dialog__value > span {
  justify-content: flex-end;
}
.data-grid td .dialog .dialog__value + div > button.MuiButtonBase-root.MuiIconButton-root {
  padding: 1px 1px 1px 5px;
  margin-right: -2px;
}
.data-grid td .dialog .dialog__value .text-cell {
  width: calc(100% - 4.3em);
  right: 1.8em;
  min-width: 100px;
}
.data-grid td .dialog .dialog__value .dropdown-icon {
  position: absolute;
  right: 2.2em;
}
.data-grid td .link {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.data-grid td .link .link__value {
  width: 2rem;
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 14px;
}
.data-grid td .link .link__icon {
  opacity: 0;
  position: absolute;
  top: -5px;
  right: -100%;
  padding-left: 1.5rem;
  background: #fff;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 50%);
}
.data-grid td .link:hover .link__icon {
  right: 0;
  opacity: 1;
  transition: ease all 0.25s;
}
.data-grid td .MuiInputBase-root {
  color: rgba(0, 0, 0, 0.5) !important;
}
.data-grid td .MuiInputBase-input {
  color: #004a8b;
  font-size: 14px;
  text-align: end;
}
.data-grid td .error-alert .MuiInputBase-input {
  color: red;
}
.data-grid td .MuiInput-underline:before {
  border-bottom: 1px solid #e3e9ed !important;
}
.data-grid td .data-grid-container .data-grid {
  background-color: #fff;
}
.data-grid td .MuiInput-root {
  max-width: 175px;
}
.data-grid td .select-value-viewer {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
}
.data-grid td .select-value-viewer .text-cell {
  font-weight: 400;
  left: 10px;
  max-width: 100%;
  padding: 0;
  position: absolute;
  right: 20px;
  text-align: right;
  top: 5px;
  width: auto;
}
.data-grid td .select-value-viewer .text-cell > span:first-child {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.data-grid td .select-value-viewer .dropdown-icon {
  position: absolute;
  right: 2px;
  top: 4px;
}
.data-grid td.grid-select-left {
  color: black;
}
.data-grid td.grid-select-left .select-value-viewer .text-cell {
  text-align: left;
  width: calc(100% - 2em);
}
.data-grid td .data-editor,
.data-grid td .value-viewer {
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  display: flex;
  height: 100%;
  width: 100%;
}
.data-grid td .cell,
.data-grid td .text-cell {
  font-size: 14px;
  max-width: 175px;
  min-width: 80%;
  width: 175px;
  padding-right: 20px;
  overflow: hidden;
  white-space: nowrap;
}
.data-grid td .cell.align-right,
.data-grid td .text-cell.align-right {
  text-align: right;
}
.data-grid td.table-header .cell,
.data-grid td.table-header .text-cell, .data-grid td.header-no-border .cell,
.data-grid td.header-no-border .text-cell, .data-grid td.table-header-comp-groups-resize-column .cell,
.data-grid td.table-header-comp-groups-resize-column .text-cell {
  white-space: normal;
  box-sizing: border-box;
  text-overflow: unset; /* Disable ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Show maximum of 3 lines */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
.data-grid td.firefox-height {
  height: -moz-max-content;
}
.data-grid td.sm-cell, .data-grid td.sm-cell .text-cell {
  max-width: 150px;
  width: 150px;
}
.data-grid td.legend.hidden-legend {
  display: none;
}
.data-grid td.legend.row-legend {
  max-width: 25px;
  padding: 0;
  width: 25px;
}
.data-grid td.legend.row-legend .text-cell,
.data-grid td.legend.row-legend .value-viewer {
  font-size: 12px;
  max-width: 25px;
  min-width: 100%;
  width: 25px;
  color: gray;
  justify-content: normal;
  text-align: center;
}
.data-grid td.legend.col-legend, .data-grid td.col-legend {
  font-size: 12px !important;
  height: 25px;
  text-align: center !important;
}
.data-grid td.legend.col-legend .value-viewer,
.data-grid td.legend.col-legend .text-cell, .data-grid td.col-legend .value-viewer,
.data-grid td.col-legend .text-cell {
  font-size: 12px !important;
  color: gray;
  text-align: center;
}
.data-grid td.row-label {
  color: black;
  background-color: #fff;
  max-width: 275px;
  min-width: 275px;
  width: 275px;
}
.data-grid td.row-label-bold small {
  font-weight: 700;
}
.data-grid td.row-label-indented small {
  padding-left: 10px;
}
.data-grid td.row-label.spreadsheet-table-title > div {
  font-weight: 700;
}
.data-grid td.row-label.spreadsheet-table-title-financials > div {
  font-weight: 700;
  height: 95px !important;
}
.data-grid td.row-label.spreadsheet-table-title-summary > div {
  height: 30.5px !important;
}
.data-grid td.row-label small {
  font-size: 14px;
}
.data-grid td.row-label.editable .data-editor {
  text-align: left;
}
.data-grid td.row-label.gray-title-cell {
  background-color: #f0f4f6;
}
.data-grid td.row-label.gray-title-cell.blue-text {
  color: #075185;
  font-weight: 600 !important;
  padding-left: 1.5rem;
}
.data-grid td.col-lg {
  min-width: 293px;
}
.data-grid td.gray-cell {
  background-color: #f0f4f6;
}
.data-grid td.title-col {
  background-color: white !important;
}
.data-grid td.onlyTopBorder {
  background-color: white !important;
  border-bottom: none !important;
  height: 31.5px !important;
}
.data-grid td.onlyBottomBorder {
  background-color: white !important;
  border-top: none !important;
  height: 31.5px !important;
}
.data-grid td.align-center .value-viewer #row-title {
  text-align: center;
}
.data-grid td.align-right .cell,
.data-grid td.align-right .text-cell {
  text-align: right;
  margin-right: 20px;
}

#fund-ownership-spreadsheet-table .data-grid td .MuiFormControl-root {
  padding: 0;
}
#fund-ownership-spreadsheet-table .data-grid td .MuiIconButton-sizeSmall {
  padding: 3px 2px 3px 6px;
}
#fund-ownership-spreadsheet-table .data-grid td .dialog__value > span {
  justify-content: flex-end;
}
#fund-ownership-spreadsheet-table .data-grid td.row-label .MuiIconButton-sizeSmall {
  padding: 3px 2px 3px 4px;
}
#fund-ownership-spreadsheet-table .data-grid td.row-label .dialog__value > span {
  justify-content: flex-end;
}

#cash-distribution-ledger .data-grid td:first-child span.value-viewer span.text-cell,
#shares-ledger .data-grid td:first-child span.value-viewer span.text-cell {
  padding-right: 20px;
}
#cash-distribution-ledger .data-grid td:first-child span.value-viewer,
#shares-ledger .data-grid td:first-child span.value-viewer {
  text-align: left;
}

#approach-opm-input-table tr td:first-child .value-viewer,
#approach-opm-input-table tr td:first-child .text-cell {
  text-align: left;
  padding-right: 0px;
  margin-left: 0px;
}

#balance-sheet-spreadsheet-table .data-grid td.row-label[data-parent=total_cash_equivalents] small, #balance-sheet-spreadsheet-table .data-grid td.row-label[data-parent=total_debt] small {
  padding-left: 10px;
}
#balance-sheet-spreadsheet-table .data-grid td.row-label[data-parent=""] small {
  font-weight: 700;
}

#scrollbar-income-statement .data-grid td .value-viewer,
#scrollbar-balance-sheet .data-grid td .value-viewer,
#scrollbar-valuation-table-dcf .data-grid td .value-viewer,
#scrollbar-financials-performance-metrics .data-grid td .value-viewer {
  text-align: right;
  width: 88%;
}
#scrollbar-income-statement .data-grid td .value-viewer .col-legend,
#scrollbar-balance-sheet .data-grid td .value-viewer .col-legend,
#scrollbar-valuation-table-dcf .data-grid td .value-viewer .col-legend,
#scrollbar-financials-performance-metrics .data-grid td .value-viewer .col-legend {
  text-align: center !important;
  width: 100%;
}
#scrollbar-income-statement .data-grid td .value-viewer .col-legend.ltm-or-ntm-legend,
#scrollbar-balance-sheet .data-grid td .value-viewer .col-legend.ltm-or-ntm-legend,
#scrollbar-valuation-table-dcf .data-grid td .value-viewer .col-legend.ltm-or-ntm-legend,
#scrollbar-financials-performance-metrics .data-grid td .value-viewer .col-legend.ltm-or-ntm-legend {
  color: #fff;
  border-bottom: none;
}
#scrollbar-income-statement .data-grid td .text-cell,
#scrollbar-balance-sheet .data-grid td .text-cell,
#scrollbar-valuation-table-dcf .data-grid td .text-cell,
#scrollbar-financials-performance-metrics .data-grid td .text-cell {
  padding-right: 0;
}

#scrollbar-valuation-table-dcf .data-grid td.row-label .value-viewer {
  text-align: center;
  width: 100%;
}

.name-table div {
  margin-left: 0.5rem;
  text-align: left;
  font-weight: bold !important;
}

.name-table-header td.table-header {
  background-color: #fff !important;
  min-width: 17.9rem;
  color: #075185;
}
.name-table-header td.comp-group-name-cell div div {
  padding-right: 20px;
}
.name-table-header td.comp-group-name-cell div div span .text-cell {
  padding-right: 0px;
  text-overflow: ellipsis;
}

.data-grid.comp-datasheet td.read-only.table-header-comp-groups {
  background-color: #fff;
  height: 53px;
  white-space: normal !important;
  min-width: 17.9rem;
  border-color: #bdc4cb;
  border-bottom: #0c71a6 solid 3px;
  color: #075185;
  font-weight: 700;
}
.data-grid.comp-datasheet td.read-only.table-header-comp-groups-resize-column {
  background-color: #fff;
  height: 53px;
  white-space: normal !important;
  border-color: #bdc4cb;
  border-bottom: #0c71a6 solid 3px;
  color: #075185;
  font-weight: 700;
  min-width: 10rem;
}

.data-grid.comp-datasheet td.first-row .text-cell {
  font-size: 16px;
}
.data-grid.comp-datasheet .transaction-comp-group-name {
  margin-right: 20px;
}
.data-grid.comp-datasheet .align-left {
  text-align: left;
}

.deleteable-row-comp-name {
  width: 18.462rem !important;
  max-width: 18.75rem !important;
  text-align: start;
}

.weighted-shares-table-title > div > small {
  font-size: 14px;
  font-weight: 700;
}

#valuation-table-container {
  width: 100%;
}

.unselected-checkbox-cell {
  margin-right: 20px;
}

#valuation-gpc-spreadsheet-table .data-grid td.row-label.weighted-ev-label small {
  font-weight: 700;
}
#valuation-gpc-spreadsheet-table .data-grid td div.select-value-viewer > span {
  margin-right: 0;
}
#valuation-gpc-spreadsheet-table .data-grid td.table-header {
  height: 1.9rem;
}

#spreadsheet-table__valuation-gpc .titles-columns-container tr td.company-title .text-cell {
  width: 100%;
  min-width: 100%;
  max-width: none;
  padding-right: 0px;
}

#allocation-spreadsheet-table .data-grid td.row-label[data-parent=aggregate_values] small, #allocation-spreadsheet-table .data-grid td.row-label[data-parent=future_values] small, #allocation-spreadsheet-table .data-grid td.row-label[data-parent=present_values] small {
  padding-left: 10px;
}
#allocation-spreadsheet-table .data-grid td .MuiSelect-select.MuiSelect-selectMenu {
  padding-right: 20px;
}
#allocation-spreadsheet-table .data-grid td svg.MuiSvgIcon-root.MuiSelect-icon {
  margin-right: -2px;
}

#smaller-target-value-table {
  width: 160px;
  margin-left: 24px;
}
#smaller-target-value-table .data-grid td .text-cell {
  max-width: 125px;
  min-width: 88%;
  text-align: right;
}
#smaller-target-value-table .data-grid td #row-title {
  width: 130px;
}

.data-grid td.large-header {
  height: 55px;
}

.data-grid tr td.no-side-border:first-child {
  border-right: none;
  border-left: 1px solid #bdc4cb;
}
.data-grid tr td.no-side-border:last-child {
  border-left: none;
  border-right: 1px solid #bdc4cb;
}
.data-grid td.no-side-border {
  border-right: none;
  border-left: none;
}

#waterfall-breakpoints .data-grid td.subtitle > div > small,
#custom-breakpoints .data-grid td.subtitle > div > small {
  font-weight: 700;
  font-size: 14px;
}
#waterfall-breakpoints .data-grid.waterfall-custom-width-spreadsheet td,
#custom-breakpoints .data-grid.waterfall-custom-width-spreadsheet td {
  max-width: 160px;
}

#distribution-table .data-grid td,
#waterfall-inputs .data-grid td {
  text-align: left;
}
#distribution-table .data-grid td .value-viewer,
#waterfall-inputs .data-grid td .value-viewer {
  text-align: left;
  justify-content: flex-start;
}
#distribution-table .data-grid td:first-child(:not(.align-right, .align-left)) .cell,
#distribution-table .data-grid td:first-child(:not(.align-right, .align-left)) .text-cell,
#waterfall-inputs .data-grid td:first-child(:not(.align-right, .align-left)) .cell,
#waterfall-inputs .data-grid td:first-child(:not(.align-right, .align-left)) .text-cell {
  text-align: left;
}
#distribution-table .data-grid td:first-child(:not(.align-right, .align-left)).table-header .value-viewer,
#waterfall-inputs .data-grid td:first-child(:not(.align-right, .align-left)).table-header .value-viewer {
  text-align: left;
}
#distribution-table .data-grid td.align-left .value-viewer,
#waterfall-inputs .data-grid td.align-left .value-viewer {
  text-align: left;
  justify-content: flex-start;
}
#distribution-table .data-grid td.align-right .value-viewer,
#waterfall-inputs .data-grid td.align-right .value-viewer {
  text-align: right;
  justify-content: flex-end;
}
#distribution-table .data-grid td.align-right .value-viewer .text-cell,
#waterfall-inputs .data-grid td.align-right .value-viewer .text-cell {
  margin-right: 0px;
}
#distribution-table .data-grid td.align-right .cell,
#distribution-table .data-grid td.align-right .text-cell,
#waterfall-inputs .data-grid td.align-right .cell,
#waterfall-inputs .data-grid td.align-right .text-cell {
  text-align: right;
  margin-right: 0px;
}
#distribution-table .data-grid td#CELL-ev .value-viewer,
#waterfall-inputs .data-grid td#CELL-ev .value-viewer {
  text-align: right;
}

#distribution-table .data-grid td,
#distribution-table tr {
  height: 2em;
}

.valuation-summary-sc-spreadsheet td.formatter-cell {
  height: 31.5px !important;
}
.valuation-summary-sc-spreadsheet td.formatter-cell.summary-table-header-valuations {
  height: 95px !important;
}

.valuation-trends-sc-spreadsheet td.formatter-cell,
.valuation-methods-detail-sc-spreadsheet td.formatter-cell,
.comparison-companies-sc-spreadsheet td.formatter-cell,
.valuation-gpc-sc-spreadsheet td.formatter-cell,
.valuation-gpt-sc-spreadsheet td.formatter-cell,
.income-statement-sc-spreadsheet td.formatter-cell,
.balance-sheet-sc-spreadsheet td.formatter-cell {
  height: 31px !important;
}
.valuation-trends-sc-spreadsheet td.formatter-cell.gpc-table-header,
.valuation-methods-detail-sc-spreadsheet td.formatter-cell.gpc-table-header,
.comparison-companies-sc-spreadsheet td.formatter-cell.gpc-table-header,
.valuation-gpc-sc-spreadsheet td.formatter-cell.gpc-table-header,
.valuation-gpt-sc-spreadsheet td.formatter-cell.gpc-table-header,
.income-statement-sc-spreadsheet td.formatter-cell.gpc-table-header,
.balance-sheet-sc-spreadsheet td.formatter-cell.gpc-table-header {
  height: 62px !important;
}
.valuation-trends-sc-spreadsheet td.formatter-cell.trends-table-header,
.valuation-methods-detail-sc-spreadsheet td.formatter-cell.trends-table-header,
.comparison-companies-sc-spreadsheet td.formatter-cell.trends-table-header,
.valuation-gpc-sc-spreadsheet td.formatter-cell.trends-table-header,
.valuation-gpt-sc-spreadsheet td.formatter-cell.trends-table-header,
.income-statement-sc-spreadsheet td.formatter-cell.trends-table-header,
.balance-sheet-sc-spreadsheet td.formatter-cell.trends-table-header {
  height: 63px !important;
}
.valuation-trends-sc-spreadsheet td.formatter-cell.comparison-companies-table-header, .valuation-trends-sc-spreadsheet td.formatter-cell.val-methods-table-header,
.valuation-methods-detail-sc-spreadsheet td.formatter-cell.comparison-companies-table-header,
.valuation-methods-detail-sc-spreadsheet td.formatter-cell.val-methods-table-header,
.comparison-companies-sc-spreadsheet td.formatter-cell.comparison-companies-table-header,
.comparison-companies-sc-spreadsheet td.formatter-cell.val-methods-table-header,
.valuation-gpc-sc-spreadsheet td.formatter-cell.comparison-companies-table-header,
.valuation-gpc-sc-spreadsheet td.formatter-cell.val-methods-table-header,
.valuation-gpt-sc-spreadsheet td.formatter-cell.comparison-companies-table-header,
.valuation-gpt-sc-spreadsheet td.formatter-cell.val-methods-table-header,
.income-statement-sc-spreadsheet td.formatter-cell.comparison-companies-table-header,
.income-statement-sc-spreadsheet td.formatter-cell.val-methods-table-header,
.balance-sheet-sc-spreadsheet td.formatter-cell.comparison-companies-table-header,
.balance-sheet-sc-spreadsheet td.formatter-cell.val-methods-table-header {
  height: 63px !important;
}
.valuation-trends-sc-spreadsheet td.formatter-cell.comparison-companies-table-header .value-viewer > .text-cell, .valuation-trends-sc-spreadsheet td.formatter-cell.val-methods-table-header .value-viewer > .text-cell,
.valuation-methods-detail-sc-spreadsheet td.formatter-cell.comparison-companies-table-header .value-viewer > .text-cell,
.valuation-methods-detail-sc-spreadsheet td.formatter-cell.val-methods-table-header .value-viewer > .text-cell,
.comparison-companies-sc-spreadsheet td.formatter-cell.comparison-companies-table-header .value-viewer > .text-cell,
.comparison-companies-sc-spreadsheet td.formatter-cell.val-methods-table-header .value-viewer > .text-cell,
.valuation-gpc-sc-spreadsheet td.formatter-cell.comparison-companies-table-header .value-viewer > .text-cell,
.valuation-gpc-sc-spreadsheet td.formatter-cell.val-methods-table-header .value-viewer > .text-cell,
.valuation-gpt-sc-spreadsheet td.formatter-cell.comparison-companies-table-header .value-viewer > .text-cell,
.valuation-gpt-sc-spreadsheet td.formatter-cell.val-methods-table-header .value-viewer > .text-cell,
.income-statement-sc-spreadsheet td.formatter-cell.comparison-companies-table-header .value-viewer > .text-cell,
.income-statement-sc-spreadsheet td.formatter-cell.val-methods-table-header .value-viewer > .text-cell,
.balance-sheet-sc-spreadsheet td.formatter-cell.comparison-companies-table-header .value-viewer > .text-cell,
.balance-sheet-sc-spreadsheet td.formatter-cell.val-methods-table-header .value-viewer > .text-cell {
  padding-right: 0 !important;
  text-overflow: initial !important;
  white-space: break-spaces !important;
}
.valuation-trends-sc-spreadsheet td.formatter-cell.summary-table-header-valuations,
.valuation-methods-detail-sc-spreadsheet td.formatter-cell.summary-table-header-valuations,
.comparison-companies-sc-spreadsheet td.formatter-cell.summary-table-header-valuations,
.valuation-gpc-sc-spreadsheet td.formatter-cell.summary-table-header-valuations,
.valuation-gpt-sc-spreadsheet td.formatter-cell.summary-table-header-valuations,
.income-statement-sc-spreadsheet td.formatter-cell.summary-table-header-valuations,
.balance-sheet-sc-spreadsheet td.formatter-cell.summary-table-header-valuations {
  height: 95px !important;
}
.valuation-trends-sc-spreadsheet td.formatter-cell.row-span-2,
.valuation-methods-detail-sc-spreadsheet td.formatter-cell.row-span-2,
.comparison-companies-sc-spreadsheet td.formatter-cell.row-span-2,
.valuation-gpc-sc-spreadsheet td.formatter-cell.row-span-2,
.valuation-gpt-sc-spreadsheet td.formatter-cell.row-span-2,
.income-statement-sc-spreadsheet td.formatter-cell.row-span-2,
.balance-sheet-sc-spreadsheet td.formatter-cell.row-span-2 {
  height: 63px !important;
}
.valuation-trends-sc-spreadsheet td.formatter-cell.row-span-3,
.valuation-methods-detail-sc-spreadsheet td.formatter-cell.row-span-3,
.comparison-companies-sc-spreadsheet td.formatter-cell.row-span-3,
.valuation-gpc-sc-spreadsheet td.formatter-cell.row-span-3,
.valuation-gpt-sc-spreadsheet td.formatter-cell.row-span-3,
.income-statement-sc-spreadsheet td.formatter-cell.row-span-3,
.balance-sheet-sc-spreadsheet td.formatter-cell.row-span-3 {
  height: 95px !important;
}
.valuation-trends-sc-spreadsheet td.row-span-2,
.valuation-methods-detail-sc-spreadsheet td.row-span-2,
.comparison-companies-sc-spreadsheet td.row-span-2,
.valuation-gpc-sc-spreadsheet td.row-span-2,
.valuation-gpt-sc-spreadsheet td.row-span-2,
.income-statement-sc-spreadsheet td.row-span-2,
.balance-sheet-sc-spreadsheet td.row-span-2 {
  height: 63px !important;
}
.valuation-trends-sc-spreadsheet td.row-span-3,
.valuation-methods-detail-sc-spreadsheet td.row-span-3,
.comparison-companies-sc-spreadsheet td.row-span-3,
.valuation-gpc-sc-spreadsheet td.row-span-3,
.valuation-gpt-sc-spreadsheet td.row-span-3,
.income-statement-sc-spreadsheet td.row-span-3,
.balance-sheet-sc-spreadsheet td.row-span-3 {
  height: 95px !important;
}

.kpi-sc-spreadsheet div#spreadsheet-table__title-columns .row-label span.value-viewer {
  justify-content: flex-start;
  text-align: left;
}
.kpi-sc-spreadsheet td.row-span-3 {
  height: 95px !important;
}
.kpi-sc-spreadsheet td.formatter-cell[data-row-span="1"] {
  height: 31px !important;
}
.kpi-sc-spreadsheet td.formatter-cell[data-row-span="2"] {
  height: 63px !important;
}
.kpi-sc-spreadsheet td.formatter-cell[data-row-span="3"] {
  height: 95px !important;
}

.valuation-gpc-sc-spreadsheet td.table-header .value-viewer,
.valuation-gpt-sc-spreadsheet td.table-header .value-viewer {
  justify-content: flex-end;
}
.valuation-gpc-sc-spreadsheet td.table-header.full-width-label.table-header .value-viewer,
.valuation-gpt-sc-spreadsheet td.table-header.full-width-label.table-header .value-viewer {
  text-align: center;
  margin-right: 0;
}

.valuation-gpt-sc-spreadsheet td.table-header .text-cell {
  display: inline;
}

.financials-performance-metrics-sc-spreadsheet td.row-span-3 {
  height: 95px !important;
}

.valuation-methods-detail-sc-spreadsheet .data-grid td.row-label,
.valuation-trends-sc-spreadsheet .data-grid td.row-label {
  max-width: 400px !important;
  width: 400px !important;
}

.waterfall-calculator-sc-spreadsheet #spreadsheet-table__content-columns {
  width: auto !important;
}

.inline-tables {
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 1em;
}
.inline-tables .data-table {
  display: inline-block;
  margin-right: 1em;
}

.data-grid-container .data-grid,
.data-grid-container .data-grid.data-sheet td.read-only {
  background-color: #f9fcfc;
}

.data-grid.data-sheet td.read-only.table-header {
  background-color: #fff;
  height: 53px;
  white-space: normal !important;
  min-width: 130px;
}
.data-grid.data-sheet td.read-only span.text-cell {
  width: 123px;
}
.data-grid.data-sheet td.read-only:first-child span.value-viewer a,
.data-grid.data-sheet td.read-only:first-child span.value-viewer span {
  width: 175px;
  text-align: left;
  padding-left: 10px;
}
.data-grid.data-sheet td.read-only.wide-cell-y {
  height: 36px;
}

.cell.ltr-left, .cell.rtl-left {
  box-shadow: inset 3px 0px 0px #037de8;
}
.cell.ltr-right, .cell.rtl-right {
  box-shadow: inset -3px 0px 0px #037de8;
}

.dragging-column-container {
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.dragging-column {
  width: 175px;
  border: 2px solid #004a8b;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1000;
  background-color: #fff;
}

.custom-width {
  max-width: inherit !important;
}

.title-first div,
.title-first-alternative div {
  text-align: left;
  font-weight: bold !important;
}

.title-second div {
  text-align: left !important;
  margin-left: 2em;
}
.title-second div .effective-tax-rate-chip {
  margin-right: 2em;
}

.title-third div {
  text-align: left;
  margin-left: 3em;
  text-transform: capitalize;
}

.title-first-alternative b,
.title-second b,
.title-third b {
  font-weight: normal !important;
}

.bigger-row-label {
  max-width: 410px !important;
  min-width: 410px !important;
  width: 410px !important;
}

.bigger-row-label-wacc-ledger {
  max-width: 470px !important;
  min-width: 470px !important;
  width: 470px !important;
  background-color: white !important;
}

.title-center div {
  text-align: center !important;
}

.spreadsheet-table-allocation-title-summary-valuation .text-cell {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
}

.ledger-dialog .data-grid.white-table-header {
  background-color: white;
}
.ledger-dialog .data-grid.white-table-header td.table-header {
  background-color: white !important;
}

.breakeven-cell {
  max-width: none !important;
  width: auto !important;
}

.data-grid-container .data-grid.data-sheet td.cell[data-disabled=true], .data-grid-container .data-grid.data-sheet td.cell.read-only[data-disabled=true] {
  background-color: #f0f4f6;
  border-color: #f0f4f6;
  box-shadow: none;
  color: #8a9097;
  cursor: auto;
}

.summary-content .data-grid.data-sheet td.table-header.read-only .cell,
.summary-content .data-grid.data-sheet td.table-header.read-only .text-cell {
  padding: 5px 0;
  padding-right: 20px;
  text-align: right;
  text-overflow: clip;
  overflow: visible;
  white-space: normal;
  min-width: 0;
  max-width: none;
  width: 100%;
}
.summary-content .data-grid.data-sheet td.table-header.read-only:first-child .cell,
.summary-content .data-grid.data-sheet td.table-header.read-only:first-child .text-cell {
  text-align: left;
  padding-left: 10px;
}
.summary-content .data-grid.data-sheet td:not(.table-header).read-only span.text-cell {
  margin: 0 auto;
  margin-right: 0;
}
.summary-content.valuations-summary .data-grid.data-sheet td.table-header.read-only:nth-child(3) .cell,
.summary-content.valuations-summary .data-grid.data-sheet td.table-header.read-only:nth-child(3) .text-cell, .summary-content.valuations-summary .data-grid.data-sheet td.table-header.read-only:nth-child(4) .cell,
.summary-content.valuations-summary .data-grid.data-sheet td.table-header.read-only:nth-child(4) .text-cell {
  text-align: left;
  padding-left: 10px;
  padding-right: 0;
}
.summary-content.valuations-summary .data-grid.data-sheet td:not(.table-header).read-only:nth-child(4) .cell,
.summary-content.valuations-summary .data-grid.data-sheet td:not(.table-header).read-only:nth-child(4) .text-cell {
  text-align: left;
  padding-left: 10px;
}
.summary-content.valuations-summary .data-grid.data-sheet td:not(.table-header).read-only:nth-child(4) span.text-cell {
  margin-right: auto;
  margin-left: 0;
}
.summary-content.valuations-summary .data-grid.data-sheet td:not(.table-header).read-only .MuiInputBase-input {
  padding-left: 10px;
}

#security-shares-table .data-grid td:first-child .cell,
#security-shares-table .data-grid td:first-child .text-cell {
  text-align: left;
}
#security-shares-table .data-grid td.align-right .cell,
#security-shares-table .data-grid td.align-right .text-cell {
  text-align: right;
}

#spreadsheet-table__opm .data-grid td.table-header #row-title {
  text-align: right;
  margin-right: 20px;
}
#spreadsheet-table__backsolve-summary-table .name-table div, #spreadsheet-table__market-adjustment-table .name-table div {
  margin-left: 0;
}
#spreadsheet-table__backsolve-summary-table .data-grid td:first-child .value-viewer, #spreadsheet-table__market-adjustment-table .data-grid td:first-child .value-viewer {
  text-align: left;
}
#spreadsheet-table__market-adjustment-table .data-grid td .text-cell {
  text-overflow: ellipsis;
}
#spreadsheet-table__market-adjustment-ledger .data-grid td .value-viewer .title-align-left {
  text-align: left;
}
#spreadsheet-table__allocation-scenarios .data-grid td .text-cell {
  display: inline;
  box-sizing: content-box;
}

.transactions-table-comps.data-grid td.table-header-comp-groups.first-row #row-title,
.gpc-table-comps.data-grid td.table-header-comp-groups.first-row #row-title {
  text-align: left;
}

.data-grid.name-table-header td.table-header .value-viewer {
  text-align: left;
}

#cap-table-selection .data-grid tbody td:first-child.table-header .value-viewer {
  text-align: left;
}

.external-valuation-sc-spreadsheet #spreadsheet-table__content-columns .data-grid tr:nth-child(2) .text-cell {
  white-space: nowrap !important;
  display: inline !important;
}

.custom-breakpoints-sc-spreadsheet #spreadsheet-table__content-columns .data-grid tr td.table-header .value-viewer .text-cell,
.calculated-breakpoints-sc-spreadsheet #spreadsheet-table__content-columns .data-grid tr td.table-header .value-viewer .text-cell {
  display: inline;
  padding-right: 20px;
}

#spreadsheet-table__convertible-note-ledger #spreadsheet-table__content-columns .table-header {
  max-width: 175px;
}
#spreadsheet-table__convertible-note-ledger #spreadsheet-table__content-columns .table-header .value-viewer .text-cell {
  display: inline;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

#spreadsheet-table__future-equity-value .data-grid td.titles-column-selector .select-value-viewer .text-cell,
#spreadsheet-table__modified-present-equity-value .data-grid td.titles-column-selector .select-value-viewer .text-cell,
#spreadsheet-table__allocation-method-table .data-grid td.titles-column-selector .select-value-viewer .text-cell,
#spreadsheet-table__future-values-per-share .data-grid td.titles-column-selector .select-value-viewer .text-cell,
#spreadsheet-table__present-values-per-share .data-grid td.titles-column-selector .select-value-viewer .text-cell {
  text-align: left !important;
  min-width: 90%;
}
#spreadsheet-table__future-equity-value .data-grid td.row-label.indented,
#spreadsheet-table__modified-present-equity-value .data-grid td.row-label.indented,
#spreadsheet-table__allocation-method-table .data-grid td.row-label.indented,
#spreadsheet-table__future-values-per-share .data-grid td.row-label.indented,
#spreadsheet-table__present-values-per-share .data-grid td.row-label.indented {
  padding-left: 15px;
}
#spreadsheet-table__future-equity-value .data-grid td.row-label.read-only,
#spreadsheet-table__modified-present-equity-value .data-grid td.row-label.read-only,
#spreadsheet-table__allocation-method-table .data-grid td.row-label.read-only,
#spreadsheet-table__future-values-per-share .data-grid td.row-label.read-only,
#spreadsheet-table__present-values-per-share .data-grid td.row-label.read-only {
  background-color: #f9fcfc;
  color: black;
}

#spreadsheet-table__allocation-method-table .data-grid td.row-label-title {
  max-width: 285px !important;
  min-width: 285px !important;
  width: 285px !important;
  color: black !important;
  font-weight: normal !important;
}
#spreadsheet-table__allocation-method-table #spreadsheet-table__content-columns .data-grid td.row-label {
  max-width: 165px !important;
  min-width: 165px !important;
  width: 165px !important;
  color: #0c71a6 !important;
  font-weight: normal !important;
}

#spreadsheet-table__future-values-per-share #spreadsheet-table__content-columns .data-grid td.row-label,
#spreadsheet-table__present-values-per-share #spreadsheet-table__content-columns .data-grid td.row-label {
  max-width: 285px !important;
  min-width: 285px !important;
  width: 285px !important;
  color: black !important;
  font-weight: normal !important;
}

.valuations-container .spreadsheet-container .data-grid td.w-full .value-viewer .text-cell {
  max-width: none;
  min-width: auto;
  padding: 0 20px;
  width: 100%;
}
.valuations-container .spreadsheet-container .data-grid td.text-center .value-viewer .text-cell {
  text-align: center;
}
.valuations-container .valuations-valuation-summary-container .spreadsheet-container .data-grid td.row-label {
  min-width: 304px;
}
.valuations-container .valuations-valuation-summary-container .spreadsheet-container .data-grid td.large-header, .valuations-container .valuations-valuation-summary-container .spreadsheet-container .data-grid td.subtitle, .valuations-container .valuations-valuation-summary-container .spreadsheet-container .data-grid td.table-header {
  min-width: 210px;
}
.valuations-container .valuations-valuation-summary-container .spreadsheet-container .data-grid td.table-header,
.valuations-container .valuations-valuation-summary-container .spreadsheet-container .data-grid td.table-header .text-cell {
  min-width: 210px;
}
.valuations-container .valuations-equity-allocation-container .spreadsheet-container .data-grid td.row-label,
.valuations-container .valuations-weighted-share-values-container .spreadsheet-container .data-grid td.row-label {
  min-width: 310px;
}
.valuations-container .valuations-equity-allocation-container .spreadsheet-container .data-grid td.large-header, .valuations-container .valuations-equity-allocation-container .spreadsheet-container .data-grid td.subtitle, .valuations-container .valuations-equity-allocation-container .spreadsheet-container .data-grid td.table-header,
.valuations-container .valuations-weighted-share-values-container .spreadsheet-container .data-grid td.large-header,
.valuations-container .valuations-weighted-share-values-container .spreadsheet-container .data-grid td.subtitle,
.valuations-container .valuations-weighted-share-values-container .spreadsheet-container .data-grid td.table-header {
  min-width: 210px;
}
.valuations-container .valuations-equity-allocation-container .spreadsheet-container .data-grid td.table-header,
.valuations-container .valuations-equity-allocation-container .spreadsheet-container .data-grid td.table-header .text-cell,
.valuations-container .valuations-weighted-share-values-container .spreadsheet-container .data-grid td.table-header,
.valuations-container .valuations-weighted-share-values-container .spreadsheet-container .data-grid td.table-header .text-cell {
  min-width: 210px;
}

.MuiButton-outlined {
  border-width: 2px !important;
}

.btn-table-aligned {
  margin-left: 12px !important;
}

.MuiDialog-container .MuiDialogTitle-root {
  padding-top: 24px;
}
.MuiDialog-container .MuiDialogTitle-root h2 {
  font-size: 18px !important;
}
.MuiDialog-container #opm-inputs tr td .text-cell {
  text-align: right;
  padding-right: 20px;
  margin-right: 0px;
}
.MuiDialog-container #opm-inputs tr td:first-child .value-viewer, .MuiDialog-container #opm-inputs tr td:first-child .text-cell {
  text-align: left;
  padding-right: 0px;
  margin-left: 0px;
}
.MuiDialog-container #opm-input-table .data-grid td.table-header span#row-title {
  text-align: right;
  padding-right: 20px;
}
.MuiDialog-container #opm-input-table .data-grid td:first-child.table-header span#row-title {
  text-align: left;
  padding-right: 0px;
  margin-left: 0px;
}
.MuiDialog-container #opm-input-table .data-grid td:first-child span.value-viewer {
  text-align: left;
  padding-right: 0px;
  margin-left: 0px;
}
.MuiDialog-container .equity-value-table .data-grid td .MuiInputBase-input {
  text-align: end;
}
.MuiDialog-container .equity-value-table .data-grid td .MuiInputAdornment-positionEnd {
  margin-left: 4px;
}
.MuiDialog-container .data-grid td .MuiInputBase-input {
  text-align: left;
}
.MuiDialog-container .data-grid td:first-child span.value-viewer {
  text-align: right;
}
.MuiDialog-container .data-grid td:first-child span.value-viewer span.text-cell {
  padding-right: 0;
}
.MuiDialog-container .data-grid td.align-left:first-child span.value-viewer {
  text-align: left !important;
}
.MuiDialog-container #spreadsheet-table__title-columns .data-grid td:first-child span.value-viewer, .MuiDialog-container #spreadsheet-table__title-columns .data-grid td .legend {
  text-align: center;
}
.MuiDialog-container #spreadsheet-table__title-columns .data-grid td:first-child span.value-viewer span.text-cell, .MuiDialog-container #spreadsheet-table__title-columns .data-grid td .legend span.text-cell {
  padding-right: 20px;
}
.MuiDialog-container div[id*=__content-columns] .data-grid td .MuiInputBase-input {
  text-align: right;
}
.MuiDialog-container div[id*=__content-columns] .data-grid td span.value-viewer {
  text-align: right;
}
.MuiDialog-container div[id*=__content-columns] .data-grid td span.value-viewer span.text-cell {
  padding-right: 20px;
}
.MuiDialog-container div[id*=__content-columns] .data-grid td.legend span.value-viewer {
  text-align: center;
}
.MuiDialog-container div[id*=__content-columns] .data-grid td.legend span.value-viewer span.text-cell {
  padding-right: 0px;
}
.MuiDialog-container #spreadsheet-table__total-column .data-grid td span.value-viewer {
  text-align: right;
}
.MuiDialog-container #spreadsheet-table__total-column .data-grid td span.value-viewer span.text-cell {
  padding-right: 20px;
}
.MuiDialog-container #spreadsheet-table__total-column .data-grid td .table-header {
  text-align: center;
}
.MuiDialog-container #cash-distribution-ledger .data-grid tr:not(:first-child) td.read-only div > div > div > div, .MuiDialog-container #shares-ledger .data-grid tr:not(:first-child) td.read-only div > div > div > div {
  text-align: left !important;
  justify-content: flex-start !important;
  margin-left: 6px !important;
}

.drawer__paper.drawer__paper--gray .MuiDrawer-paper {
  background-color: #f0f4f6;
  max-width: 500px;
}

.scar-link--container {
  padding: 5px;
}

.MuiAccordionSummary-content.Mui-expanded .morevert-icon-container {
  top: 12px;
}

.accordion-item-with-dropdown-menu .MuiIconButton-edgeEnd {
  margin-right: 35px;
}

.is-benchmark-checkBox {
  padding: 0px 0px 0px 9px !important;
}

.morevert-icon-container {
  top: 0px;
  display: flex;
  align-items: center;
}

.titles-columns-container,
.total-columns-container {
  padding-top: 12px;
}

.titles-columns-container {
  padding-left: 12px;
}

#conclusion-table,
#weighted-share-value {
  padding-left: 12px;
}

.grid-bottom-margin {
  margin-bottom: 5em !important;
}

.scar-textField .MuiInput-formControl:before {
  border-color: #f0f4f6;
}
.scar-textField label {
  color: #8a9097;
  font-size: 0.85em;
}

.Microsoft-icon {
  padding-left: 15px;
  padding-bottom: 5px;
}

.MuiTooltip-popper {
  z-index: 9999 !important;
}

.MuiTooltip-tooltip {
  white-space: normal;
  width: max-content;
}

#custom-auto-select-popover .MuiPopover-paper {
  max-height: 35% !important;
}

#folder-backdrop {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.1);
}

#folders-content {
  width: 100%;
}
#folders-content > div {
  padding: 0;
}

div#documents .folder.MuiPaper-elevation1 {
  border-bottom: none;
}
div#documents .droppable {
  border: none;
  background-color: white;
  transform: scale(1);
  transition: 0.15s all ease;
}
div#documents .droppable.drag-over:not(div.dragging) {
  border: dashed 2px #0386de;
  transform: scale(1);
  transition: 0.15s all ease;
  background-color: #f0f4f6;
}

.MuiTreeItem-root {
  padding: 0px 5px !important;
}
.MuiTreeItem-root .MuiTreeItem-content .MuiTreeItem-iconContainer {
  color: #037de8;
}
.MuiTreeItem-root .MuiTreeItem-content .MuiTreeItem-label {
  padding: 0.5rem;
}
.MuiTreeItem-root .MuiTreeItem-content .MuiTreeItem-label:hover {
  background-color: #f0f4f6;
}

.custom-tree-container .MuiCollapse-root {
  border-left-color: #037de8;
}
.custom-tree-container .MuiFormHelperText-contained {
  margin-top: 0.5rem;
  margin-left: 0 !important;
  opacity: 0.5;
}

.add-new-folder-button .MuiListItem-gutters {
  padding-left: 0.125rem;
}
.add-new-folder-button .MuiListItemIcon-root {
  min-width: 1.875rem;
}

.MuiTablePagination-root {
  width: auto;
}
.MuiTablePagination-root .MuiToolbar-gutters {
  padding-left: 1rem;
}
.MuiTablePagination-root .MuiTablePagination-toolbar {
  color: grey;
  font-size: 1rem;
  padding-right: 1rem;
}
.MuiTablePagination-root .MuiTablePagination-caption {
  order: 1;
}
.MuiTablePagination-root .MuiTablePagination-selectRoot {
  margin-left: 0;
  order: 2;
}
.MuiTablePagination-root .MuiTablePagination-spacer {
  order: 3;
}
.MuiTablePagination-root .MuiTablePagination-caption:nth-of-type(2n) {
  order: 4;
}
.MuiTablePagination-root .MuiTablePagination-actions {
  order: 5;
}

.data-grid {
  table-layout: auto;
  border-collapse: collapse;
}
.data-grid .col-legend.ltm-or-ntm-legend {
  background-color: #0386de;
  color: whitesmoke;
  border-bottom: 1px solid #76c4f5;
  border-left: 1px solid #0386de;
  border-right: 1px solid #0386de;
}
.data-grid .column-legend__icons {
  display: none;
}
.data-grid .column-legend__icon {
  padding: 0;
  margin: 0 0.25rem;
}
.data-grid .column-legend__icon svg {
  width: 1rem;
  height: 1rem;
}
.data-grid .column-legend:hover .column-legend__legend.has-icons {
  display: none;
}
.data-grid .column-legend:hover .column-legend__icons {
  display: block;
}

.data-grid-container,
.data-grid-container:focus {
  outline: none;
}

.data-sheet {
  width: 100%;
}

.security-table-sc-spreadsheet .data-grid-container table {
  background-color: transparent;
}

.message-box {
  text-align: center;
  margin: auto;
}
.message-box__container {
  text-align: center;
  padding: 32px 64px;
  margin: auto;
  max-width: 500px;
}
.message-box__title {
  color: #037de8;
  margin-bottom: 16px;
}
.message-box__tagline {
  margin-bottom: 16px;
  line-height: 1.75;
}
.message-box__icon {
  font-size: 3rem !important;
  padding: 1.5rem;
  margin-bottom: 16px;
  background-color: #afe0ff;
  color: #fff;
  border-radius: 50%;
}
.message-box__inside-icon-ok {
  font-size: 2.5rem !important;
  padding: 2rem;
  margin-bottom: 16px;
  background-color: #65c687;
  color: #fff;
  border-radius: 50%;
}
.message-box__inside-icon-error {
  font-size: 1.5rem !important;
  padding: 1.5rem;
  margin-bottom: 16px;
  background-color: #ff4070;
  color: #fff;
  border-radius: 50%;
}
.message-box__icon-error-disabled {
  font-size: 3rem !important;
  padding: 1.5rem;
  margin-bottom: 16px;
  background-color: #8a9097;
  color: #fff;
  border-radius: 50%;
}
.message-box--with-frame .message-box__container {
  padding: 64px;
}
.message-box--with-bg-color .message-box__container {
  background-color: #f0f4f6;
  padding: 32px 16px;
  margin: 16px 8px;
}
.message-box--full-with .message-box__container {
  padding: 64px;
  max-width: 100%;
}
.message-box--info .message-box__icon {
  color: #fff;
  background-color: #037de8;
}
.message-box--success .message-box__container {
  background-color: #e4fce3;
  border: 2px solid #65c687;
}
.message-box--success .message-box__icon {
  color: #fff;
  background-color: #65c687;
}
.message-box--error .message-box__icon {
  color: #fff;
  background-color: #ff4070;
}
.message-box--error .message-box__container {
  background-color: #fff5f8;
  border: 2px solid #ff4070;
}
.message-box--error .message-box__title {
  color: #ff4070;
}
.message-box--error-disabled .message-box__icon {
  color: #fff;
  background-color: #ff4070;
}
.message-box--error-disabled .message-box__title {
  color: #8a9097;
}

.inline-message-box {
  display: inline-block;
  margin: 16px 0;
  width: auto;
}
.inline-message-box__container {
  align-items: center;
  background-color: #f0f4f6 !important;
  border: 2px solid #8a9097;
  display: flex;
  justify-content: center;
  padding: 4px 8px;
}
.inline-message-box__text {
  color: #8a9097;
  flex: 1;
  font-weight: bold;
  line-height: 1.75;
}
.inline-message-box__icon {
  color: #8a9097;
  font-size: 1.75rem !important;
  margin-right: 8px;
  padding: 0.5rem;
  padding-left: 0;
}
.inline-message-box--full-with {
  width: 100%;
}

.widgets-container {
  margin: 2rem 1rem;
}

.widget-content {
  box-shadow: none !important;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
  margin-right: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.hidden,
.invisible {
  display: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.full-width {
  width: 100%;
}

.cell.legend-hidden {
  width: 1px !important;
  height: 1px !important;
}
.cell.legend-hidden .value-viewer {
  display: none !important;
}

.scrollbar-container {
  padding-top: 12px;
}
.scrollbar-container.ps--active-x {
  padding-bottom: 1.25em;
}
.scrollbar-container.ps--active-x .ps__rail-x {
  background: #bdc4cb;
  opacity: 1;
  height: 10px;
  transition: height ease 0.3s;
  border-radius: 12px;
}
.scrollbar-container.ps--active-x .ps__rail-x:hover, .scrollbar-container.ps--active-x .ps__rail-x:focus, .scrollbar-container.ps--active-x .ps__rail-x.ps--clicking {
  height: 15px;
}

@media only screen and (max-width: 128rem) {
  .hs-grid-container {
    overflow-x: scroll;
  }
  .hs-grid-container .hs-grid {
    width: 100%;
  }
  .hs-grid-container .hs-grid tbody tr:first-child td:first-child {
    line-height: 4;
    min-height: 64px;
    position: absolute;
    width: 350px;
    z-index: 1;
  }
  .hs-grid-container .hs-grid tbody tr:not(:first-child) td:first-child {
    position: absolute;
    width: 350px;
    min-height: 35px;
    margin-top: -1px;
  }
  .hs-grid-container .hs-grid tbody tr > td,
  .hs-grid-container .hs-grid tbody tr > td:nth-child(2) {
    line-height: 2;
    width: 150px;
  }
  .hs-grid-container .hs-grid tbody tr > td:nth-child(2) {
    padding-left: 380px !important;
  }
}
@media only screen and (min-width: 129rem) {
  .hs-grid {
    width: 100%;
  }
  .hs-grid tbody tr:first-child td:first-child,
  .hs-grid tbody tr:not(:first-child) td:first-child {
    position: relative !important;
  }
  .hs-grid-container .hs-grid tbody tr > td:nth-child(2) {
    padding-left: 10px !important;
  }
}